import { AmplienceCtaContentData } from '@Types/amplience/amplienceComponents';
import AmplienceButton from '../amplience-button';
import styles from './amplience-cta-list-wrapper.module.scss';

type AmplienceItemsPosition = 'left' | 'right' | 'center' | 'top';
export interface ButtonProps {
  button: AmplienceCtaContentData;
}
export interface Props {
  content: {
    _meta: any;
    buttonPosition: AmplienceItemsPosition;
    buttonList: ButtonProps[];
  };
}
const AmplienceCtaListWrapper = ({ content }: Props) => {
  return (
    <div
      className={styles.ctaListWrapperContainer}
      style={{
        justifyContent: content.buttonPosition,
      }}
      data-testid="ctaListWrapperContainer"
    >
      {content.buttonList.map((val, index) => {
        return (
          <div key={index} className={styles.buttonWrapper}>
            <AmplienceButton content={val.button}></AmplienceButton>
          </div>
        );
      })}
    </div>
  );
};

export default AmplienceCtaListWrapper;
